<template>
  <div>
    <div id="editor"></div>
  </div>
</template>

<script>
import CodeFlask from 'codeflask';

export default {
  name: 'CustomCssEditor',
  props: {
    cssCode: {
      type: String,
      default: null,
    },
  },
  mounted() {
    const flask = new CodeFlask('#editor', {
      language: 'css',
      lineNumbers: true,
    });
    flask.updateCode(this.cssCode || '');
    flask.onUpdate((code) => {
      this.$emit('update-cssCode', code);
    });
  },
};
</script>

<style scoped lang="scss">
#editor {
  min-height: 500px;
  width: 100%;
  height: 92%;
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(#000, .14),
  0 1px 5px 0 rgba(#000, .12), 0 3px 1px -2px rgba(#000, .2);
  /deep/ .codeflask {
    height: 92%;
    border-radius: 3px;
    width: calc(100% - 30px);
    z-index: 3;
    &::before, .codeflask__lines {
      background: #FFF;
      width: 40px;
    }
    .codeflask__textarea {
      left: 40px;
      background-color: #fff;
    }
    .codeflask__code {
      overflow: hidden;
    }
  }
}
</style>
